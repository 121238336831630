// MobileDonationSection.tsx

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Label, Description } from "@headlessui/react";
import SupportScholarIcon from "../../../../assets/images/support-scholar3.png";
import {
  setDonationAmountAction,
  setCustomDonationAmountAction,
  selectCheckout,
} from "../../slices/checkoutSlice";
import { AppDispatch } from "../../../../app/store";

const MobileDonationSection: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { donationOptions } = useSelector(selectCheckout);

  const [donationAmount, setDonationAmountState] = useState("");
  const [customAmount, setCustomAmount] = useState("");

  const handleDonationChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setDonationAmountState(event.target.value);
    if (event.target.value !== "other") {
      setCustomAmount("");
    }
  };

  const handleCustomAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCustomAmount(event.target.value);
  };

  const handleDonateClick = () => {
    const joinSessionUuid = localStorage.getItem("joinSessionUuid");
    if (!joinSessionUuid) {
      console.error("Join session UUID not found");
      return;
    }

    if (donationAmount === "other" && customAmount) {
      const amount = parseFloat(customAmount);
      if (amount > 0) {
        dispatch(setCustomDonationAmountAction(joinSessionUuid, amount));
        setDonationAmountState("");
        setCustomAmount("");
      }
    } else if (donationAmount) {
      // donationAmount contains the productUuid
      const productUuid = donationAmount;
      dispatch(setDonationAmountAction(joinSessionUuid, productUuid));
      setDonationAmountState("");
      setCustomAmount("");
    }
  };

  return (
    <div className="border border-gray-300 p-4 rounded-lg bg-white text-center mt-6 md:hidden">
      <h3 className="text-lg font-medium text-gray-800 mb-2">
        Support Torch & Laurel Programming
      </h3>
      <img
        alt="Support a Scholar"
        className="w-16 mx-auto mb-4"
        src={SupportScholarIcon}
      />
      <p className="text-sm text-gray-600 mb-4">
        Help support Torch and Laurel programming, powered by NSCS, for our
        high-achieving scholars.
      </p>

      {/* Donation Amount Selection */}
      <Field>
        <Label className="text-sm font-medium text-gray-800">
          Select donation amount
        </Label>
        <Description className="text-sm text-gray-600 mb-2">
          Your donation will make a difference!
        </Description>
        <div className="relative">
          <select
            className="mt-3 block w-full appearance-none rounded-lg border border-gray-300 bg-gray-100 py-1.5 px-3 text-sm text-gray-800"
            onChange={handleDonationChange}
            value={donationAmount}
          >
            <option value="">Select an amount</option>
            {donationOptions.map((option) => (
              <option key={option.id} value={option.uuid}>
                ${option.amount}
              </option>
            ))}
            <option value="other">Other</option>
          </select>
        </div>
      </Field>

      {/* Custom Amount Input Field */}
      {donationAmount === "other" && (
        <div className="mt-4">
          <label
            className="block text-sm font-medium text-gray-800"
            htmlFor="custom-donation"
          >
            Enter custom donation amount
          </label>
          <input
            className="mt-2 block w-full rounded-lg border border-gray-300 py-2 px-3 text-sm text-gray-800 focus:outline-none focus:ring-primary focus:border-primary"
            id="custom-donation"
            onChange={handleCustomAmountChange}
            placeholder="Enter amount"
            type="number"
            value={customAmount}
          />
        </div>
      )}

      {/* Donate Button */}
      <button
        className="mt-4 bg-primary text-white w-full py-2 rounded-md font-medium hover:bg-primary-dark"
        onClick={handleDonateClick}
      >
        Donate
      </button>
    </div>
  );
};

export default MobileDonationSection;
