import axiosInstance from "../axiosInstance";
import { AxiosProgressEvent } from "axios";
import { UploadResumeResponse } from "./types";

/**
 * Uploads a resume to /api/public/upload-resume using multipart/form-data.
 *
 * @param formData - Should contain both the file and the contactId (either as JSON or a simple field).
 * @param onUploadProgress - Callback to track upload progress in the UI, optional.
 * @returns Promise<UploadResumeResponseData> containing { success: boolean, message?: string, result?: any }
 */
export const uploadResume = async (
  formData: FormData,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
): Promise<UploadResumeResponse> => {
  const response = await axiosInstance.post<UploadResumeResponse>(
    `/api/public/upload-resume`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    },
  );

  return response.data;
};
