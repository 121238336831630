import axios from "../axiosInstance";
import { InitializeCheckoutRequest, InitializeCheckoutResponse } from "./types";

export const initializeCheckout = async (
  requestData: InitializeCheckoutRequest,
): Promise<InitializeCheckoutResponse> => {
  const response = await axios.post<InitializeCheckoutResponse>(
    `/api/public/checkout/initiate`,
    requestData,
  );
  return response.data;
};
