import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "../../../app/store";
import {
  GetUpgradeDetailsResponse,
  GetUpgradeDetailsData,
  UpgradeOption,
  UpgradePriceFeature,
} from "../../../api/getUpgradeDetails/types";
import { getUpgradeDetails } from "../../../api/getUpgradeDetails/getUpgradeDetailsApi";

interface UpgradeDetailsState {
  loading: boolean;
  successMessage: string | null;
  error: string | null;
  upgradeOption: UpgradeOption | null;
  isEligibleForUpgrade: boolean;
  isSelfNomination: boolean;
  features: UpgradePriceFeature[];
  congratulationsHeader: string | null;
  congratulationsSubheader: string | null;
  upgradeHeader: string | null;
  upgradeSubheader: string | null;
}

const initialState: UpgradeDetailsState = {
  loading: true,
  successMessage: null,
  error: null,
  upgradeOption: null,
  isEligibleForUpgrade: false,
  isSelfNomination: false,
  features: [],
  congratulationsHeader: null,
  congratulationsSubheader: null,
  upgradeHeader: null,
  upgradeSubheader: null,
};

const upgradeDetails = createSlice({
  name: "upgradeDetails",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSuccessMessage: (state, action: PayloadAction<string | null>) => {
      state.successMessage = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setUpgradeDetails: (
      state,
      action: PayloadAction<GetUpgradeDetailsData | null>,
    ) => {
      if (action.payload) {
        state.upgradeOption = action.payload.upgradeOption;
        state.isEligibleForUpgrade = action.payload.isEligibleForUpgrade;
        state.isSelfNomination = action.payload.isSelfNomination;
        state.features = action.payload.features;
        state.congratulationsHeader = action.payload.congratulationsHeader;
        state.congratulationsSubheader =
          action.payload.congratulationsSubheader;
        state.upgradeHeader = action.payload.upgradeHeader;
        state.upgradeSubheader = action.payload.upgradeSubheader;
      } else {
        state.upgradeOption = null;
        state.isEligibleForUpgrade = false;
        state.isSelfNomination = false;
        state.features = [];
        state.congratulationsHeader = null;
        state.congratulationsSubheader = null;
        state.upgradeHeader = null;
        state.upgradeSubheader = null;
      }
    },
  },
});

export const { setLoading, setSuccessMessage, setError, setUpgradeDetails } =
  upgradeDetails.actions;

export const fetchUpgradeDetails =
  (joinSessionUuid: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const response: GetUpgradeDetailsResponse =
        await getUpgradeDetails(joinSessionUuid);

      const {
        upgradeOption,
        isEligibleForUpgrade,
        isSelfNomination,
        features,
        congratulationsHeader,
        congratulationsSubheader,
        upgradeHeader,
        upgradeSubheader,
      } = response.data;

      dispatch(
        setUpgradeDetails({
          upgradeOption,
          isEligibleForUpgrade,
          isSelfNomination,
          features,
          congratulationsHeader,
          congratulationsSubheader,
          upgradeHeader,
          upgradeSubheader,
        }),
      );
    } catch (error) {
      dispatch(
        setError(
          error instanceof Error
            ? error.message
            : "Failed to fetch upgrade details.",
        ),
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

export default upgradeDetails.reducer;
