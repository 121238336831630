import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "../../../app/store";
import { RootState } from "../../../app/rootReducer";
import {
  DonationOption,
  GetCheckoutDetailsResponse,
  Product,
  RecommendedProduct,
} from "../../../api/getCheckoutDetails/types";
import { getCheckoutDetails } from "../../../api/getCheckoutDetails/getCheckoutDetailsApi";
import {
  SetSessionProductRequest,
  SetSessionProductResponse,
} from "../../../api/setSessionProduct/types";
import { setSessionProduct } from "../../../api/setSessionProduct/setSessionProductApi";
import {
  SetCustomDonationRequest,
  SetCustomDonationResponse,
} from "../../../api/setCustomDonation/types";
import { setCustomDonation } from "../../../api/setCustomDonation/setCustomDonationApi";
import {
  CreatePaymentIntentRequest,
  CreatePaymentIntentResponse,
} from "../../../api/createPaymentIntent/types";
import { createPaymentIntent } from "../../../api/createPaymentIntent/createPaymentIntentApi";
import { deleteSessionProductApi } from "../../../api/deleteSessionProduct/deleteSessionProductApi";
import { DeleteSessionProductResponse } from "../../../api/deleteSessionProduct/types";

interface CheckoutState {
  loading: boolean;
  error: string | null;
  membership: Product | null;
  donationOptions: DonationOption[];
  donations: Product[];
  storeProducts: Product[];
  recommendedProducts: RecommendedProduct[];
  subtotal: number;
  tax: number;
  totalDueToday: number;
  clientSecret: string | null;
  billingEmail: string;
  isCheckoutOnly: boolean;
}

const initialState: CheckoutState = {
  loading: false,
  error: null,
  membership: null,
  donationOptions: [],
  donations: [],
  storeProducts: [],
  recommendedProducts: [],
  subtotal: 0,
  tax: 0,
  totalDueToday: 0,
  clientSecret: null,
  billingEmail: "",
  isCheckoutOnly: false,
};

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setMembership: (state, action: PayloadAction<Product | null>) => {
      state.membership = action.payload;
      recalculateTotals(state);
    },
    setDonationOptions: (state, action: PayloadAction<DonationOption[]>) => {
      state.donationOptions = action.payload;
    },
    setDonations: (state, action: PayloadAction<Product[]>) => {
      state.donations = action.payload;
      recalculateTotals(state);
    },
    addDonation: (state, action: PayloadAction<Product>) => {
      state.donations.push(action.payload);
      recalculateTotals(state);
    },
    removeDonation: (state, action: PayloadAction<string>) => {
      state.donations = state.donations.filter(
        (donation) => donation.sessionProductUuid !== action.payload,
      );
      recalculateTotals(state);
    },
    setStoreProducts: (state, action: PayloadAction<Product[]>) => {
      state.storeProducts = action.payload;
      recalculateTotals(state);
    },
    removeStoreProduct: (state, action: PayloadAction<string>) => {
      state.storeProducts = state.storeProducts.filter(
        (product) => product.sessionProductUuid !== action.payload,
      );
      recalculateTotals(state);
    },
    setRecommendedProducts: (
      state,
      action: PayloadAction<RecommendedProduct[]>,
    ) => {
      state.recommendedProducts = action.payload;
    },
    addStoreProduct: (state, action: PayloadAction<Product>) => {
      state.storeProducts.push(action.payload);
      recalculateTotals(state);
    },
    setSubtotal: (state, action: PayloadAction<number>) => {
      state.subtotal = action.payload;
    },
    setTax: (state, action: PayloadAction<number>) => {
      state.tax = action.payload;
    },
    setTotalDueToday: (state, action: PayloadAction<number>) => {
      state.totalDueToday = action.payload;
    },
    setBillingEmail: (state, action: PayloadAction<string>) => {
      state.billingEmail = action.payload;
    },
    setClientSecret: (state, action: PayloadAction<string | null>) => {
      state.clientSecret = action.payload;
    },
    setIsCheckoutOnly: (state, action: PayloadAction<boolean>) => {
      state.isCheckoutOnly = action.payload;
    },
  },
});

export const {
  setLoading,
  setError,
  setMembership,
  setDonationOptions,
  setDonations,
  addDonation,
  removeDonation,
  setStoreProducts,
  removeStoreProduct,
  setRecommendedProducts,
  addStoreProduct,
  setSubtotal,
  setTax,
  setTotalDueToday,
  setClientSecret,
  setBillingEmail,
  setIsCheckoutOnly,
} = checkoutSlice.actions;

const recalculateTotals = (state: CheckoutState) => {
  const membershipPrice = state.membership
    ? parseFloat(state.membership.price)
    : 0;
  const donationTotal = state.donations.reduce(
    (sum, donation) => sum + parseFloat(donation.price),
    0,
  );
  const storeTotal = state.storeProducts.reduce(
    (sum, product) => sum + parseFloat(product.price),
    0,
  );
  const subtotal = membershipPrice + donationTotal + storeTotal;
  const tax = 0; // Assuming tax is 0
  const totalDueToday = subtotal + tax;
  state.subtotal = subtotal;
  state.tax = tax;
  state.totalDueToday = totalDueToday;
};

export const fetchCheckoutDetails =
  (joinSessionUuid: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const response: GetCheckoutDetailsResponse =
        await getCheckoutDetails(joinSessionUuid);
      const data = response.data;

      dispatch(setDonationOptions(data.donationOptions));
      dispatch(setBillingEmail(data.billingEmail));
      dispatch(setIsCheckoutOnly(data.isCheckoutOnly));

      const products = data.products;
      const membershipProduct =
        products.find(
          (product) =>
            product.productType === "membership" ||
            product.productType === "membership_upgrade",
        ) || null;

      dispatch(setMembership(membershipProduct));

      const donationProducts = products.filter(
        (product) =>
          product.productType === "donation" ||
          product.productType === "donation_other",
      );
      dispatch(setDonations(donationProducts));

      const storeProducts = products.filter(
        (product) => product.productType === "store",
      );
      dispatch(setStoreProducts(storeProducts));

      const recommendedProducts = data.recommendedProducts || [];
      dispatch(setRecommendedProducts(recommendedProducts));
    } catch (error) {
      dispatch(
        setError(
          error instanceof Error
            ? error.message
            : "Failed to fetch checkout details",
        ),
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

export const setDonationAmountAction =
  (joinSessionUuid: string, productUuid: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const requestData: SetSessionProductRequest = { productUuid };
      const response: SetSessionProductResponse = await setSessionProduct(
        joinSessionUuid,
        requestData,
      );

      const newDonation: Product = {
        sessionProductUuid: response.data.sessionProductUuid,
        productId: response.data.productId,
        name: response.data.name,
        price: response.data.price,
        displayPrice: response.data.displayPrice,
        description: response.data.description,
        icon: response.data.icon,
        productType: response.data.productType,
        sortOrder: response.data.sortOrder,
      };

      dispatch(addDonation(newDonation));
    } catch (error) {
      dispatch(
        setError(
          error instanceof Error
            ? error.message
            : "Failed to set donation amount",
        ),
      );
    }
  };

export const setCustomDonationAmountAction =
  (joinSessionUuid: string, amount: number): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const requestData: SetCustomDonationRequest = { amount };
      const response: SetCustomDonationResponse = await setCustomDonation(
        joinSessionUuid,
        requestData,
      );

      const newDonation: Product = {
        sessionProductUuid: response.data.sessionProductUuid,
        productId: response.data.productId,
        name: response.data.name,
        price: response.data.price,
        displayPrice: response.data.displayPrice,
        description: response.data.description,
        icon: response.data.icon,
        productType: response.data.productType,
        sortOrder: response.data.sortOrder,
      };

      dispatch(addDonation(newDonation));
    } catch (error) {
      dispatch(
        setError(
          error instanceof Error
            ? error.message
            : "Failed to set custom donation amount",
        ),
      );
    }
  };

export const deleteDonationAction =
  (sessionProductUuid: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const response: DeleteSessionProductResponse =
        await deleteSessionProductApi(sessionProductUuid);
      if (response.status === "success") {
        dispatch(removeDonation(sessionProductUuid));
      } else {
        dispatch(setError("Failed to delete donation"));
      }
    } catch (error) {
      dispatch(
        setError(
          error instanceof Error ? error.message : "Failed to delete donation",
        ),
      );
    }
  };

export const deleteStoreProductAction =
  (sessionProductUuid: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const response: DeleteSessionProductResponse =
        await deleteSessionProductApi(sessionProductUuid);
      if (response.status === "success") {
        dispatch(removeStoreProduct(sessionProductUuid));
      } else {
        dispatch(setError("Failed to delete store product"));
      }
    } catch (error) {
      dispatch(
        setError(
          error instanceof Error
            ? error.message
            : "Failed to delete store product",
        ),
      );
    }
  };

// New action for adding recommended product
export const addRecommendedProductAction =
  (joinSessionUuid: string, productUuid: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const requestData: SetSessionProductRequest = { productUuid };
      const response: SetSessionProductResponse = await setSessionProduct(
        joinSessionUuid,
        requestData,
      );

      const newStoreProduct: Product = {
        sessionProductUuid: response.data.sessionProductUuid,
        productId: response.data.productId,
        name: response.data.name,
        price: response.data.price,
        displayPrice: response.data.displayPrice,
        description: response.data.description,
        icon: response.data.icon,
        productType: response.data.productType,
        sortOrder: response.data.sortOrder,
      };

      dispatch(addStoreProduct(newStoreProduct));
    } catch (error) {
      dispatch(
        setError(
          error instanceof Error ? error.message : "Failed to add product",
        ),
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

export const createPaymentIntentAction =
  (joinSessionUuid: string, amount: number, billingEmail: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const requestData: CreatePaymentIntentRequest = {
        amount,
        currency: "usd",
        billingEmail,
      };
      const response: CreatePaymentIntentResponse = await createPaymentIntent(
        joinSessionUuid,
        requestData,
      );
      dispatch(setClientSecret(response.data.clientSecret));
    } catch (error) {
      dispatch(
        setError(
          error instanceof Error
            ? error.message
            : "Failed to create payment intent",
        ),
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

export const selectCheckout = (state: RootState) => state.checkout;

export default checkoutSlice.reducer;
