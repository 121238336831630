import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "../../../app/store";
import {
  InitializeCheckoutRequest,
  InitializeCheckoutResponse,
} from "../../../api/initializeCheckout/types";
import { initializeCheckout } from "../../../api/initializeCheckout/initializeCheckoutApi";

interface InitializeCheckoutState {
  loading: boolean;
  successMessage: string | null;
  error: string | null;
}

const initialState: InitializeCheckoutState = {
  loading: false,
  successMessage: null,
  error: null,
};

const initializeCheckoutSlice = createSlice({
  name: "initializeCheckout",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSuccessMessage: (state, action: PayloadAction<string | null>) => {
      state.successMessage = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const { setLoading, setSuccessMessage, setError } =
  initializeCheckoutSlice.actions;

export const initializeCheckoutAction =
  (
    hubspotContactId: string,
    mainProductId?: number,
    upsellProductId?: number,
    overrideMainPriceCents?: number,
    callback?: () => void,
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const requestData: InitializeCheckoutRequest = {
        hubspotContactId,
        mainProductId,
        upsellProductId,
        overrideMainPriceCents,
      };
      const response: InitializeCheckoutResponse =
        await initializeCheckout(requestData);

      localStorage.setItem("joinSessionUuid", response.data.joinSessionUuid);

      dispatch(setSuccessMessage(response.data.message));

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(
          error instanceof Error
            ? error.message
            : "Failed to initiate checkout.",
        ),
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

export default initializeCheckoutSlice.reducer;
