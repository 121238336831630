import React, { useEffect } from "react";
import "./App.css";
import AppRoutes from "./app/AppRoutes";
import axiosInstance, { addAccessTokenInterceptor } from "./api/axiosInstance";
import { useNotification } from "./context/NotificationContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "./app/rootReducer";

function App() {
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    addAccessTokenInterceptor(axiosInstance, showNotification);

    const joinSessionUuid = localStorage.getItem("joinSessionUuid");
    const currentPath = location.pathname;

    if (currentPath === "/registration/initiate/upgrade") {
      return;
    }

    if (
      !joinSessionUuid &&
      currentPath !== "/" &&
      currentPath !== "/registration/get-started"
    ) {
      navigate("/");
    }
  }, [showNotification, navigate, location.pathname]);

  return <AppRoutes />;
}

export default App;
