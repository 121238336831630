import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosProgressEvent } from "axios";
import { uploadResume } from "../../../../api/uploadResume/uploadResumeApi";
import Header from "../../../../components/Header/Header";
import {Helmet} from "react-helmet-async";

const ResumeUpload: React.FC = () => {
  const { contactId } = useParams();
  const navigate = useNavigate();

  const [uploadedFiles, setUploadedFiles] = useState<
    Array<{ name: string; size: number }>
  >([]);
  const [currentUpload, setCurrentUpload] = useState<{
    file: File | null;
    uploading: boolean;
    progress: number;
    error: string | null;
  }>({
    file: null,
    uploading: false,
    progress: 0,
    error: null,
  });

  // New state to track a successful upload
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (!contactId) {
        alert("No contact ID provided in the URL!");
        return;
      }

      if (acceptedFiles.length > 0) {
        const fileToUpload = acceptedFiles[0];
        setCurrentUpload({
          file: fileToUpload,
          uploading: true,
          progress: 0,
          error: null,
        });

        const formData = new FormData();
        formData.append("file", fileToUpload);
        // If your Symfony controller reads from `$request->request->get('contactId')`:
        formData.append("contactId", contactId);

        uploadResume(formData, (progressEvent: AxiosProgressEvent) => {
          const percentCompleted = progressEvent.total
            ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
            : 0;
          setCurrentUpload((prevState) => ({
            ...prevState,
            progress: percentCompleted,
          }));
        })
          .then((response) => {
            if (response.data.success) {
              setUploadedFiles((prevFiles) => [
                ...prevFiles,
                { name: fileToUpload.name, size: fileToUpload.size },
              ]);

              setCurrentUpload({
                file: null,
                uploading: false,
                progress: 0,
                error: null,
              });

              // Indicate we've successfully uploaded
              setUploadSuccess(true);
            } else {
              setCurrentUpload({
                file: null,
                uploading: false,
                progress: 0,
                error: response.data.message || "Failed to upload file.",
              });
            }
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
            setCurrentUpload({
              file: null,
              uploading: false,
              progress: 0,
              error: "Failed to upload file.",
            });
          });
      }
    },
    [contactId],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "image/*": [".jpg", ".jpeg", ".png"],
      "application/msword": [".doc", ".docx"],
    },
    maxFiles: 1,
  });

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen py-10 px-5"
      style={{
        backgroundImage: "linear-gradient(135deg, #A99268 50%, #981B1E 50%)",
      }}
    >
      <Helmet>
        <title>Resume Upload - National Society of Collegiate Scholars</title>
      </Helmet>
      <div className="w-full max-w-4xl px-4 py-10 bg-white rounded-lg shadow-xl relative">
        <Header />

        {!uploadSuccess && (
          <>
            <div className="text-center mb-6">
              <h2 className="text-2xl font-bold text-primary mb-2">
                Upload Your Resume
              </h2>
              <p className="text-sm text-gray-500">
                Drag and drop or select a file to upload your resume.
              </p>
            </div>

            <div
              {...getRootProps()}
              className={`p-10 border-dashed border-2 rounded-lg text-center cursor-pointer ${
                isDragActive ? "border-indigo-600" : "border-gray-300"
              }`}
            >
              <input {...getInputProps()} />
              <FaCloudUploadAlt className="text-primary text-4xl mb-3" />
              <p className="text-gray-600">
                {isDragActive
                  ? "Drop the file here ..."
                  : "Drag & drop a file here, or click to select a file"}
              </p>
            </div>

            {currentUpload.uploading && currentUpload.file && (
              <div className="mt-6">
                <h4 className="text-lg font-semibold mb-2">
                  Uploading: {currentUpload.file.name}
                </h4>
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                  <div
                    className="bg-indigo-600 h-2.5 rounded-full"
                    style={{ width: `${currentUpload.progress}%` }}
                  ></div>
                </div>
              </div>
            )}

            {currentUpload.error && (
              <div className="mt-4 p-3 rounded-lg text-center bg-red-100 text-red-700">
                {currentUpload.error}
              </div>
            )}
          </>
        )}

        {uploadSuccess && (
          <div className="mt-6 text-center">
            <h3 className="text-lg font-semibold mb-2 text-primary">
              Resume Uploaded Successfully!
            </h3>
            <p className="text-gray-700">
              Thank you, your resume was successfully uploaded and is being
              processed. You can safely close this window now.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResumeUpload;
