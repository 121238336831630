import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Checkout from "../features/Checkout/components/Checkout/Checkout";
import Upgrade from "../features/Upgrade/components/Upgrade/Upgrade";
import GetStarted from "../features/Registration/components/GetStarted/GetStarted";
import MembershipSelection from "../features/Registration/components/MembershipSelection/MembershipSelection";
import StudentDetails from "../features/Registration/components/StudentDetails/StudentDetails";
import PostPaymentHandler from "../features/Checkout/components/PostPaymentHandler/PostPaymentHandler";
import Congratulations from "../features/Congratulations/components/Congratulations";
import SelfNomTranscriptUpload from "../features/SelfNomTranscriptUpload/components/SelfNomTranscriptUpload/SelfNomTranscriptUpload";
import InitializeCheckout from "../features/Checkout/components/InitializeCheckout/InitializeCheckout";
import ResumeUpload from "../features/ResumeUpload/components/ResumeUpload/ResumeUpload";

function AppRoutes() {
  // Check if the app is in resume-only mode
  const isResumeOnly = process.env.REACT_APP_RESUME_ONLY === "true";

  return (
    <Routes>
      {!isResumeOnly && (
        <>
          <Route element={<GetStarted />} path="/registration/get-started" />
          <Route
            element={<StudentDetails />}
            path="/registration/student-details"
          />
          <Route
            element={<MembershipSelection />}
            path="/registration/membership"
          />
          <Route element={<Checkout />} path="/registration/checkout" />
          <Route element={<Upgrade />} path="/registration/upgrade" />
          <Route element={<InitializeCheckout />} path="/initialize/checkout" />
          <Route
            element={<Navigate replace to="/registration/get-started" />}
            path="/"
          />
          <Route
            element={<PostPaymentHandler />}
            path="/registration/post-payment"
          />
          <Route
            element={<Congratulations />}
            path="/registration/congratulations"
          />
          <Route
            element={<SelfNomTranscriptUpload />}
            path="/registration/upload-transcript"
          />
        </>
      )}

      {/* Always include the resume upload route */}
      <Route element={<ResumeUpload />} path="/upload-resume/:contactId" />
    </Routes>
  );
}

export default AppRoutes;
