import axios from "../axiosInstance";
import { DeleteSessionProductResponse } from "./types";

export const deleteSessionProductApi = async (
  sessionProductUuid: string,
): Promise<DeleteSessionProductResponse> => {
  const response = await axios.post<DeleteSessionProductResponse>(
    `/api/public/session-product/${sessionProductUuid}/delete`,
  );
  return response.data;
};
