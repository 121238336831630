import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../app/store";
import { initializeCheckoutAction } from "../../slices/initializeCheckoutSlice";
import { RootState } from "../../../../app/rootReducer";

const InitializeCheckout: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { loading, error } = useSelector(
    (state: RootState) => state.initializeCheckout,
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const hubspotContactId = searchParams.get("vid");
    const mainProductId = searchParams.get("mp");
    const upsellProductId = searchParams.get("usp");
    const overrideMainPriceCents = searchParams.get("c");

    if (hubspotContactId) {
      dispatch(
        initializeCheckoutAction(
          hubspotContactId,
          mainProductId ? parseInt(mainProductId, 10) : undefined,
          upsellProductId ? parseInt(upsellProductId, 10) : undefined,
          overrideMainPriceCents
            ? parseInt(overrideMainPriceCents, 10)
            : undefined,
          () => {
            navigate("/registration/checkout");
          },
        ),
      );
    } else {
      console.error("Missing hubspotContactId in URL parameters.");
    }
  }, [location.search, dispatch, navigate]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div className="text-red-500 p-4">Error: {error}</div>;
  }

  return null;
};

export default InitializeCheckout;
