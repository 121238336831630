import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import BillingAndPayment from "../BillingAndPayment/BillingAndPayment";
import { Helmet } from "react-helmet-async";
import Loader from "../../../../components/Loader";
import {
  createPaymentIntentAction,
  fetchCheckoutDetails,
  selectCheckout,
} from "../../slices/checkoutSlice";
import { AppDispatch } from "../../../../app/store";
import MobileOrderSummary from "../MobileOrderSummary/MobileOrderSummary";
import MobileOrderSummaryHeader from "../MobileOrderSummaryHeader/MobileOrderSummaryHeader";
import OrderSummary from "../OrderSummary/OrderSummary";

const stripePromise: Promise<Stripe | null> = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!,
);

const Checkout: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { clientSecret, loading, error, totalDueToday, billingEmail } =
    useSelector(selectCheckout);

  const [showOrderSummary, setShowOrderSummary] = useState(false);

  useEffect(() => {
    const joinSessionUuid = localStorage.getItem("joinSessionUuid");
    if (joinSessionUuid) {
      dispatch(fetchCheckoutDetails(joinSessionUuid));
    } else {
      console.error("Join session UUID not found");
    }
  }, [dispatch]);

  useEffect(() => {
    const joinSessionUuid = localStorage.getItem("joinSessionUuid");
    if (totalDueToday > 0 && joinSessionUuid) {
      const amountInCents = Math.round(totalDueToday * 100);
      dispatch(
        createPaymentIntentAction(joinSessionUuid, amountInCents, billingEmail),
      );
    }
  }, [dispatch, totalDueToday]);

  const toggleOrderSummary = () => {
    setShowOrderSummary(!showOrderSummary);
  };

  const closeSummary = () => {
    setShowOrderSummary(false);
  };

  const options: any | undefined = clientSecret
    ? {
        clientSecret,
        appearance: { theme: "stripe" },
      }
    : undefined;

  return (
    <>
      <Helmet>
        <title>Checkout - National Society of Collegiate Scholars</title>
      </Helmet>
      {error && <div className="text-red-500 p-4">{error}</div>}
      {clientSecret ? (
        <Elements options={options} stripe={stripePromise}>
          <div className="flex flex-col md:flex-row h-screen w-full relative">
            <MobileOrderSummaryHeader
              showOrderSummary={showOrderSummary}
              toggleOrderSummary={toggleOrderSummary}
              totalDueToday={totalDueToday}
            />

            {/* Slide-Down Mobile Order Summary */}
            <MobileOrderSummary
              closeSummary={closeSummary}
              isVisible={showOrderSummary}
            />

            {/* Desktop Order Summary */}
            <div
              className={`hidden md:block bg-primary w-full md:w-1/2 h-screen overflow-auto`}
            >
              <OrderSummary />
            </div>

            {/* Payment Form */}
            <div className="w-full md:w-1/2 h-screen overflow-auto">
              <BillingAndPayment />
            </div>
          </div>
        </Elements>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Checkout;
