import axios from "../axiosInstance";
import { SetSessionProductRequest, SetSessionProductResponse } from "./types";

export const setSessionProduct = async (
  joinSessionUuid: string,
  requestData: SetSessionProductRequest,
): Promise<SetSessionProductResponse> => {
  const response = await axios.post<SetSessionProductResponse>(
    `/api/public/join-session/${joinSessionUuid}/set-session-product`,
    requestData,
  );
  return response.data;
};
