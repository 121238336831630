import React from "react";
import { useSelector, useDispatch } from "react-redux";
import NSCSLogoWhite from "../../../../assets/images/nscs_logo_white.svg";
import TOLALogoWhite from "../../../../assets/images/tola_logo_white.svg";
import {
  deleteDonationAction,
  deleteStoreProductAction,
  addRecommendedProductAction,
  selectCheckout,
} from "../../slices/checkoutSlice";
import { AppDispatch } from "../../../../app/store";
import DonationSection from "../DonationSection/DonationSection";
import RecommendedProductsSection from "../RecommendedProductsSection/RecommendedProductsSection";

const OrderSummary: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isTola = process.env.REACT_APP_TOLA === "true";
  const {
    membership,
    subtotal,
    tax,
    totalDueToday,
    donations,
    storeProducts,
    recommendedProducts,
  } = useSelector(selectCheckout);

  const handleDeleteDonation = (sessionProductUuid: string) => {
    dispatch(deleteDonationAction(sessionProductUuid));
  };

  const handleDeleteStoreProduct = (sessionProductUuid: string) => {
    dispatch(deleteStoreProductAction(sessionProductUuid));
  };

  const handleAddRecommendedProduct = (productUuid: string) => {
    // Assuming you have joinSessionUuid stored locally (e.g. localStorage)
    const joinSessionUuid = localStorage.getItem("joinSessionUuid");
    if (!joinSessionUuid) {
      console.error("Join session UUID not found");
      return;
    }
    dispatch(addRecommendedProductAction(joinSessionUuid, productUuid));
  };

  // Encode SVG to Base64 if it exists
  const encodedIcon = membership?.icon
    ? `data:image/svg+xml;base64,${btoa(membership.icon)}`
    : null;

  return (
    <div className="w-full relative p-10">
      <img
        alt="Climate Badge"
        className="mr-2"
        src={isTola ? TOLALogoWhite : NSCSLogoWhite}
        width="300"
      />

      <div className="flex flex-col justify-center items-center mt-10">
        <div className="w-3/4 max-w-lg">
          {/* Product Name and Logo */}
          <div className="flex items-center mb-4">
            {encodedIcon && (
              <img
                alt="Product Logo"
                className="w-8 h-8 mr-2"
                src={encodedIcon}
              />
            )}
            <h2 className="text-xl font-medium text-white">
              {membership ? membership.name : "Membership"}
            </h2>
          </div>

          {/* Price */}
          <div className="mb-4">
            <span className="text-3xl font-bold text-white">
              ${membership ? parseFloat(membership.price).toFixed(2) : "0.00"}
            </span>
            <span className="text-sm text-white ml-2">(once)</span>
          </div>

          {/* Subtotal Section */}
          <div className="border-t border-gray-300 pt-4 mb-10">
            {/* Membership Line Item */}
            <div className="flex justify-between mb-2">
              <span className="text-white">
                {membership?.name || "Membership"}
              </span>
              <span className="text-white">
                ${membership ? parseFloat(membership.price).toFixed(2) : "0.00"}
              </span>
            </div>

            {/* Donation Line Items */}
            {donations?.map((donation, index) => (
              <div
                className="flex justify-between mb-2"
                key={donation.sessionProductUuid}
              >
                <span className="text-white flex items-center">
                  {donation.name || `Donation ${index + 1}`}
                  <button
                    className="ml-2 text-blue-500 underline font-bold"
                    onClick={() =>
                      handleDeleteDonation(donation.sessionProductUuid)
                    }
                  >
                    Remove
                  </button>
                </span>
                <span className="text-white">
                  ${parseFloat(donation.price).toFixed(2)}
                </span>
              </div>
            ))}

            {/* Store Product Line Items */}
            {storeProducts?.map((product, index) => (
              <div
                className="flex justify-between mb-2"
                key={product.sessionProductUuid}
              >
                <span className="text-white flex items-center">
                  {product.name || `Store Item ${index + 1}`}
                  <button
                    className="ml-2 text-blue-500 underline font-bold"
                    onClick={() =>
                      handleDeleteStoreProduct(product.sessionProductUuid)
                    }
                  >
                    Remove
                  </button>
                </span>
                <span className="text-white">
                  ${parseFloat(product.price).toFixed(2)}
                </span>
              </div>
            ))}

            {/* Subtotal, Tax, and Total */}
            <div className="flex justify-between mb-2">
              <span className="text-white">Subtotal:</span>
              <span className="text-white">${subtotal.toFixed(2)}</span>
            </div>
            <div className="flex justify-between mb-2">
              <span className="text-white">Tax:</span>
              <span className="text-white">${tax.toFixed(2)}</span>
            </div>
            <div className="flex justify-between font-bold text-white">
              <span>Total Due Today:</span>
              <span>${totalDueToday.toFixed(2)}</span>
            </div>
          </div>

          {/* Recommended Products Section */}
          <RecommendedProductsSection
            onAddProduct={handleAddRecommendedProduct}
            recommendedProducts={recommendedProducts}
            storeProducts={storeProducts}
          />

          <DonationSection />
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
