import { combineReducers } from "@reduxjs/toolkit";
import studentDetailsReducer from "../features/Registration/slices/studentDetails";
import checkoutReducer from "../features/Checkout/slices/checkoutSlice";
import initializeCheckoutReducer from "../features/Checkout/slices/initializeCheckoutSlice";
import getStartedReducer from "../features/Registration/slices/getStartedSlice";
import membershipReducer from "../features/Registration/slices/membershipSlice";
import upgradeDetailsReducer from "../features/Upgrade/slices/upgradeDetailsSlice";
import initializeUpgradeReducer from "../features/Upgrade/slices/initializeUpgradeSlice";

const rootReducer = combineReducers({
  studentDetails: studentDetailsReducer,
  checkout: checkoutReducer,
  getStarted: getStartedReducer,
  membership: membershipReducer,
  upgradeDetails: upgradeDetailsReducer,
  initializeUpgrade: initializeUpgradeReducer,
  initializeCheckout: initializeCheckoutReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
