import React from "react";
import {
  RecommendedProduct,
  Product,
} from "../../../../api/getCheckoutDetails/types";

interface RecommendedProductsSectionProps {
  recommendedProducts: RecommendedProduct[];
  storeProducts: Product[];
  onAddProduct: (productUuid: string) => void;
}

const RecommendedProductsSection: React.FC<RecommendedProductsSectionProps> = ({
  recommendedProducts,
  storeProducts,
  onAddProduct,
}) => {
  // Filter recommended products that are not already added
  const filteredRecommendedProducts = recommendedProducts.filter((rProduct) => {
    // Check if already in storeProducts by productId
    const isInStore = storeProducts.some((sp) => sp.productId === rProduct.id);
    return !isInStore;
  });

  if (filteredRecommendedProducts.length === 0) {
    return null; // If no recommended products left to show, return nothing
  }

  return (
    <div className="border-t border-gray-300 pt-4 mb-10">
      <h3 className="text-lg font-medium text-primary md:text-white mb-4">
        You might also like
      </h3>
      {filteredRecommendedProducts.map((rProduct) => (
        <div className="flex items-center mb-2" key={rProduct.uuid}>
          <img
            alt="Recommended Product"
            className="w-16 h-16 mr-4"
            src={rProduct.icon ?? "https://via.placeholder.com/64"}
          />
          <div className="flex flex-col">
            <span className="text-primary md:text-white font-semibold">
              {rProduct.name}
            </span>
            <span className="text-primary md:text-white">
              ${rProduct.price ? parseFloat(rProduct.price).toFixed(2) : "0.00"}
            </span>
          </div>
          <button
            className="ml-auto bg-secondary text-white py-1 px-3 rounded-md font-bold hover:bg-secondary-light"
            onClick={() => onAddProduct(rProduct.uuid)}
          >
            Add
          </button>
        </div>
      ))}
    </div>
  );
};

export default RecommendedProductsSection;
