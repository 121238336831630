import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "../../../app/store";
import {
  InitiateUpgradeRequest,
  InitiateUpgradeResponse,
} from "../../../api/initiateUpgrade/types";
import { initiateUpgrade } from "../../../api/initiateUpgrade/initiateUpgradeApi";

interface InitializeUpgradeState {
  loading: boolean;
  successMessage: string | null;
  error: string | null;
}

const initialState: InitializeUpgradeState = {
  loading: false,
  successMessage: null,
  error: null,
};

const initializeUpgradeSlice = createSlice({
  name: "initializeUpgrade",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSuccessMessage: (state, action: PayloadAction<string | null>) => {
      state.successMessage = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const { setLoading, setSuccessMessage, setError } =
  initializeUpgradeSlice.actions;

export const initiateUpgradeAction =
  (hubspotContactId: string, callback?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const requestData: InitiateUpgradeRequest = { hubspotContactId };
      const response: InitiateUpgradeResponse =
        await initiateUpgrade(requestData);

      localStorage.setItem("joinSessionUuid", response.data.joinSessionUuid);

      dispatch(setSuccessMessage(response.data.message));

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(
          error instanceof Error
            ? error.message
            : "Failed to initiate upgrade.",
        ),
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

export default initializeUpgradeSlice.reducer;
