import axios from "../axiosInstance";
import { SetCustomDonationRequest, SetCustomDonationResponse } from "./types";

export const setCustomDonation = async (
  joinSessionUuid: string,
  requestData: SetCustomDonationRequest,
): Promise<SetCustomDonationResponse> => {
  const response = await axios.post<SetCustomDonationResponse>(
    `/api/public/join-session/${joinSessionUuid}/set-custom-donation`,
    requestData,
  );
  return response.data;
};
