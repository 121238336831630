import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../app/store";
import { RootState } from "../../../app/rootReducer";
import {
  checkUserEligibility,
  initiateJoinAction,
  initiateSchoolJoinAction,
  initiateSelfNominationAction,
} from "../slices/getStartedSlice";

function useGetStartedForm(isTOLA: boolean) {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [inviteCode, setInviteCode] = useState<string>("");
  const [validationError, setValidationError] = useState<string | null>(null);
  const [selectedOption, setSelectedOption] = useState<
    "inviteCode" | "selfNom"
  >("inviteCode");
  const [selfNomConfirmed, setSelfNomConfirmed] = useState<boolean>(false);

  const { loading, error } = useSelector(
    (state: RootState) => state.getStarted,
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const isValid = /^[a-zA-Z0-9]*$/.test(value);

    if (!isValid) {
      setValidationError("Invite code must contain only letters and numbers.");
    } else {
      setValidationError(null);
    }
    setInviteCode(value);
  };

  const navigateToDetails = () => {
    navigate("/registration/student-details");
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (selectedOption === "inviteCode") {
      if (!inviteCode.trim()) {
        setValidationError("Invite code is required.");
        return;
      }
      if (!validationError) {
        const callback = (contactId: string | undefined) => {
          if (contactId) {
            dispatch(
              initiateJoinAction(
                {
                  contactId,
                  invitationCode: inviteCode,
                  isTOLA,
                },
                navigateToDetails,
              ),
            );
          } else {
            dispatch(
              initiateSchoolJoinAction(
                {
                  invitationCode: inviteCode,
                },
                navigateToDetails,
              ),
            );
          }
        };

        dispatch(
          checkUserEligibility(
            { invitationCode: inviteCode, isTOLA },
            callback,
          ),
        );
      }
    } else if (selectedOption === "selfNom" && selfNomConfirmed) {
      dispatch(
        initiateSelfNominationAction(
          { hasConfirmedEligibility: true, isTOLA },
          navigateToDetails,
        ),
      );
    }
  };

  return {
    inviteCode,
    setInviteCode,
    validationError,
    setValidationError,
    selectedOption,
    setSelectedOption,
    selfNomConfirmed,
    setSelfNomConfirmed,
    loading,
    error,
    handleInputChange,
    handleSubmit,
  };
}

export default useGetStartedForm;
